<template>
    <modal ref="editarPedido" v-loading="loading" :titulo="titulo" tamano="modal-xl" icon="confirmar-pedido" no-aceptar adicional="Confirmar" @adicional="editar_pedido">
        <div class="row mx-0 ml-3 text-general f-500 mb-3">
            Datos de la entrega
        </div>
        <div class="row f-14 my-2 mx-0">
            <div class="col-7">
                <div class="row">
                    <div class="col">
                        <p class="ml-2 text-general f-13">Fecha de Entrega</p>
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_franja"
                        />
                    </div>
                    <div class="col">
                        <p class="ml-2 text-general f-13">Hora de Entrega</p>
                        <el-select v-model="model.entrega_horario" size="small" placeholder="Horario">
                            <el-option
                            v-for="item in horarios"
                            :key="item.hora"
                            :label="item.horario"
                            :value="item.hora"
                            />
                        </el-select>
                    </div>
                    <div class="col">
                        <p class="ml-2 text-general f-13">Valor Domicilio</p>
                        <div class="d-middle">
                            <money v-model="model.domicilio" class="input-money" v-bind="money" />
                            <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ pedido.sigla_moneda }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div v-show="confirmacion" class="col mx-auto my-3 d-middle">
                        <div class="col-auto pr-0">
                            <el-tooltip placement="top" content="Domiciliario" effect="light">
                                <div class="rounded-circle bg-general3 text-white d-middle-center mr-1" style="width:28px;height:28px;">
                                    <i class="icon-moped f-18" />
                                </div>
                            </el-tooltip>
                        </div>
                        <el-select v-model="model.id_user_delivery" size="small" placeholder="Seleccione delivery">
                            <el-option
                            v-for="item in deliverys"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col pl-0">
                        <p class="f-12 text-general pl-3">Forma de pago</p>
                        <el-select v-model="pedido.pago_teorico" :disabled="disableSelectNomina" size="small" placeholder="Seleccionar" @change="putFormaPagoPedido">
                            <el-option label="Efectivo" :value="1" />
                            <el-option label="Transferencia" :value="2" />
                            <el-option label="Crédito" :value="3" />
                            <el-option label="En Linea" :value="4" />
                            <el-option label="Nomina" :value="5" :disabled="disableOptionNomina" />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-5  mx-0">
                <p class="ml-2 text-general f-13">Instrucciones del pedido</p>
                <el-input
                v-model="model.instrucciones"
                type="textarea"
                rows="4"
                placeholder="Digite la instrucciones de entrega"
                maxlength="500"
                show-word-limit
                />
            </div>
        </div>

        <div v-if="pedido.id_pedido">
            <div class="row mx-0 ml-3 text-general f-500 mb-1 mt-5">
                Información de deuda
            </div>
            <div class="row mx-0 ml-3 text-general mb-3">
                <p class="f-500"> Linea de crédito: {{ convertMoney(deuda.cupo, pedido.idm_moneda) }}</p>
                <div v-if="deuda.cupo < deuda.total" class="d-flex gap-2" style="margin-left: 10px;">
                    <i class="icon-alert-triangle text-warning" />
                    <p class="text-black-50">La deuda del cliente supera su linea de crédito</p>
                </div>
            </div>
            <div v-if="deuda.cuotaPedido && deuda.cupo < deuda.total" class="w-30 my-3" style="margin-left: 60px;">
                <p class="ml-2 text-general f-13">Fecha de pago</p>
                <el-tooltip
                effect="light"
                content="Top Center prompts info"
                placement="bottom"
                >
                    <el-date-picker
                    v-model="deuda.cuotaPedido.fecha"
                    :picker-options="pickerOptions"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                    unlink-panels
                    :clearable="false"
                    popper-class="date-picker-edit-popper"
                    :disabled="!$can('botones_abastecimiento_confirmar_pedido_credito')"
                    @change="updateFechaPago"
                    />
                    <template #content>
                        <div class="d-flex gap-2">
                            <i class="icon-alert-circled" />
                            <p>Cambiar la fecha de pago</p>
                        </div>
                    </template>
                </el-tooltip>
            </div>
            <div class="row">
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.total, pedido.idm_moneda) }}</p>
                    <p class="f-14">Crédito Total</p>
                </div>
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.vigente, pedido.idm_moneda) }}</p>
                    <p class="f-14">Deuda no vencida</p>
                </div>
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.menos30, pedido.idm_moneda) }}</p>
                    <p class="f-14">Deuda a 30 días</p>
                </div>
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.menos60, pedido.idm_moneda) }}</p>
                    <p class="f-14">Deuda a 60 días</p>
                </div>
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.menos90, pedido.idm_moneda) }}</p>
                    <p class="f-14">Deuda a 90 días</p>
                </div>
                <div class="col border-right text-center text-general">
                    <p class="f-18 f-500">{{ convertMoney(deuda.mas90, pedido.idm_moneda) }}</p>
                    <p class="f-14">Deuda a + 90 días</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import Pedidos from '~/services/pedidos/pedidos-admin'
import moment from 'moment'
import {mapGetters} from 'vuex'
export default {
    components:{
        Money,
    },
    props:{
        editar:{
            type:Boolean,
            default:false
        },
        confirmacion:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            loading:false,
            horarios:[],
            deliverys:[],
            model:{
                domicilio:'',
                instrucciones:'',
                entrega_fecha:'',
                entrega_horario:'',
                id_user_delivery:null,
            },
            deuda:{},
            fecha: '',
            fechaPago: '',
            //hora: ''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido:'pedidos/pedidos_admin/pedido',
        }),
        titulo(){
            if(this.editar) return "Editar Pedido"
            if(this.confirmacion) return "Confirmar Pedido"
            return ""
        },
        disableSelectNomina(){
            if(this.pedido.pago_teorico === 5){
                return true
            }
            return false
        },
        disableOptionNomina(){
            if(this.pedido.pago_teorico !== 5){
                return true
            }
            return false
        }
    },
    methods: {
        toggle(){
            this.model.domicilio = parseFloat(this.pedido.valor_envio)
            this.model.instrucciones = this.pedido.instrucciones
            this.model.entrega_fecha = this.pedido.entrega_fecha
            this.model.entrega_horario = this.pedido.entrega_horario
            this.model.id_user_delivery = this.pedido.id_user_delivery

            this.money.precision = this.pedido.decimales_moneda


            this.set_franja(this.model.entrega_fecha)
            this.listarDelivery()
            this.getDeudaPendiente()
            this.$refs.editarPedido.toggle()
        },
        async editar_pedido(){
            try {
                if(this.model.domicilio > 65000){
                    this.notificacion('Advertencia','El costo maximo para domicilio es de $65.000','warning')
                    return
                }
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                this.loading = true
                this.model.confirmar = this.confirmacion

                if(this.confirmacion && this.deuda.cuotaPedido && this.deuda.cupo < this.deuda.total){
                    if(!this.$can('botones_abastecimiento_confirmar_pedido_credito')){
                        this.notificacion('Sin Permiso!','No tiene permiso para confirmar este pedido','warning')
                        return
                    }
                }

                const {data} = await Pedidos.editar_pedido(this.id_pedido,this.model)
                this.notificacion('Éxito','Pedido editado correctamente','success')
                if(this.confirmacion){
                    this.$store.commit('pedidos/pedidos_admin/pasar_a_confirmado',data.pedido)
                }
                this.$emit('accion-editar') // solo para entregas
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes',this.id_pedido)
                this.$store.commit('pedidos/pedidos_admin/set_pedido',data.pedido)
                this.$refs.editarPedido.toggle()
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async set_franja(fecha){
            try {
                let id_cedis = this.pedido.id_cedis
                const vistaAdmin = true

                const {data} = await Pedidos.setFranjasHorarios(id_cedis,fecha,vistaAdmin)
                this.horarios = data?.data

                // if (this.hora) this.model.entrega_horario = this.hora
                if (this.model.entrega_horario != null && this.model.entrega_horario != ''){

                    let findHorario = this.horarios.find(o=>o.hora === this.model.entrega_horario)

                    if(findHorario === undefined){
                        let horaMostrar = moment(this.model.entrega_horario,'H').format('hh:mm A')
                        //let fechaHoy = moment().format('YYYY-MM-DD')
                        let obj = {
                            'hora':  this.model.entrega_horario,
                            'horario': horaMostrar+' - '+horaMostrar
                        }
                        this.horarios.push(obj)
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarDelivery(){
            try {
                const {data} = await Pedidos.get_delivery(this.id_pedido)
                this.deliverys = data.domiciliarios
            } catch (e){
                this.error_catch(e)
            }
        },
        async getDeudaPendiente(){
            try {
                const {data} = await Pedidos.getDeudaPendiente(this.id_pedido)
                this.deuda = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async putFormaPagoPedido(value){
            try {
                const form = {
                    formaPago: value,
                };
                const {data} = await Pedidos.putFormaPagoPedido(this.id_pedido, form)
                this.notificacion('Mensaje', 'Forma pago actualizada', 'success');
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateFechaPago(fecha){
            try {
                if(this.confirmacion && !this.$can('botones_abastecimiento_confirmar_pedido_credito')){
                    this.notificacion('Sin Permiso!','No tiene permiso para cambiar esta fecha','warning')
                    return
                }
                const payload = {
                    idCreditoCuota: this.deuda.cuotaPedido.id,
                    fecha
                }
                const { data } = await Pedidos.updateFechaCuotaPago(payload);
                this.notificacion('', data.mensaje, 'success');
            } catch (error){
                return this.error_catch(error);
            }
        }
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
